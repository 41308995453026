import APIs from "@config/apis";
import { IPlaceQrPoint } from "@interface/IPlaceQrPoint";
import { AppContext } from "@store/ContextStore";
import useQuery from "@use/useQuery";
import { requestFn } from "@utils/request";
import { getSessionStore, setSessionStore } from "@utils/util";
import { useSnackbar } from "notistack";
import { createContext , useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import util from 'util';

interface LocationContextType {
    scope: number 
    distance:number 
}

export const LocationContext = createContext<LocationContextType>(null!);

export const LocationProvider = ({children}:{children:  React.ReactNode}) => {
    const KEY_PLACE = "place_info";
    const { dispatch } = useContext(AppContext);
    const [scope , setScope] = useState(0);
    const [distance , setDistance] = useState(0);
    const query = useQuery();
    function rad(d:number) {
        return d * (Math.PI / 180.0)
    }
    
    function getDistance(startLon:number , startLat:number, endLon:number ,endLat:number) {
        const EARTH_RADIUS = 6371;
        let radStartLat = rad(startLat);
        let radEndtLat = rad(endLat);
        let a = radStartLat - radEndtLat;
        let b = rad(startLon) - rad(endLon);
        let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2)
        + Math.cos(radStartLat) * Math.cos(radEndtLat)
        * Math.pow(Math.sin(b/2),2)
        ));
        s = s * EARTH_RADIUS;
        s = Math.round( s * 10000) / 10000;
        s = s * 1000;
        return s ;
    }

    const getPlaceInfo = useCallback((apikey:string , lon:number , lat:number)=>{ 
        const fetchData = async ()=>{
            const response = await requestFn(dispatch, {
                url: util.format(APIs.GET_LOCATION_API,apikey),
                method: 'get'
            });

            if(response.status === 200){
                
                let place = response.data.place as IPlaceQrPoint & { scope?:number};
                
                if(place){
                    dispatch({
                        type: 'SET_PLACE' ,  
                        payload : place
                    });

                    let distance = getDistance(lon ,lat,  place.pl_lonlat ? place.pl_lonlat.y : 0 , place.pl_lonlat ? place.pl_lonlat.x : 0 );
                    
                    if(distance < 500){
                        setScope(()=>{
                            return 1;
                        });
                    }
                    if(place.scope == 2){
                        setScope(()=>{
                            return 2;
                        });
                    } else {
                        setScope(()=>{
                            return place.scope!!;
                        });
                    }
                    setDistance(()=>{
                        return distance;
                    });
        
                }
            }

            dispatch({
                type: 'SET_CONNECTSERVER' ,  
                isConnectServer:true
            });
        }
        
        fetchData();
        
    } , [dispatch]);

    useEffect(()=>{

        const API_KEY = query.get("apikey") || getSessionStore(KEY_PLACE);
        if(API_KEY){
            setSessionStore(KEY_PLACE , API_KEY);
        }

        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition( (position:GeolocationPosition) => {
                if(API_KEY){
                    getPlaceInfo( API_KEY , position.coords.longitude , position.coords.latitude);
                } else {
                    dispatch({
                        type: 'SET_CONNECTSERVER' ,  
                        isConnectServer:true
                    });
                }
                
            } , error => {
                setScope(()=>{
                    return 5;
                });
                dispatch({
                    type: 'SET_CONNECTSERVER' ,  
                    isConnectServer:true
                });
            });
        }
    },[]);
    
    
    let value = { scope , distance };
    
    return <LocationContext.Provider value = {value}> {children} </LocationContext.Provider>
}