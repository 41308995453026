import { createContext ,Dispatch, useReducer} from "react";
import FetchActions from "@store/actions/fetchActions";
import fetchReducer, { FetchState, INITIAL_FETCH_STATE } from "@store/reducer/fetchReducer";
import fetchPlaceReducer, { FetchPlaceState, INITIAL_PLACE_STATE } from "@store/reducer/fetchPlaceReducer";
import FetchPlaceActions from "@store/actions/fetchPlaceActions";

type InitialStateType = {
  fetch: FetchState;
  place:FetchPlaceState
};
  
const initialState = {
  fetch: INITIAL_FETCH_STATE,
  place: INITIAL_PLACE_STATE,
};

const AppContext = createContext<{
  state: InitialStateType ;
  dispatch: Dispatch<FetchActions | FetchPlaceActions> ;
}>({
  state: initialState ,
  dispatch: () => null
});

const mainReducer = (
  {fetch ,place}: InitialStateType,
  action: FetchActions | FetchPlaceActions
) => ({
  fetch: fetchReducer(fetch, action as FetchActions),
  place:fetchPlaceReducer(place, action as FetchPlaceActions),
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
  
  export { AppProvider, AppContext };