import axios, { ResponseType, Method, AxiosPromise, AxiosResponse, AxiosError } from 'axios'
import ResponseStatus from '@enums/ResponseStatus';
import { IBaseResponse } from '@interface/Response/IBaseResponse';
import { getLocalStore, getSessionStore, setSessionStore } from '@utils/util';
import { IErrorResponse } from '@interface/Response/IErrorResponse';
import { Dispatch } from 'react';
import FetchActions from '@store/actions/fetchActions';

/** 默認請求超過逾時時間 */
const REQUEST_TIME_OUT = 15000;

/** request 參數接口類型 */
export interface Params {
    url: string
    method?: Method
    params?: { [key:string] : any}
    data?: { [key:string] : any} | string
    responseType?: ResponseType
}

axios.defaults.withCredentials = true
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.response.use(
    (response:AxiosResponse) => {
        let data = response.data as IBaseResponse;
        if(data.code === ResponseStatus.SUCCESS){
            return response;
        } else {
            return Promise.reject(msg(data.code));
        }
        
    } ,
    (error:AxiosError ) => {
        const config = error.config ;
        if(error.response && error.response.status === 401 && error.response.config.url?.indexOf('/refreshToken') !== -1) {
            // 刷新 Token 失敗，導引登入頁
            
            return Promise.reject(msg(ResponseStatus.UNAUTHORIZED));
            
        } else if(error.response && error.response.status === 401 ){
            // 重新獲取token
            
            return new Promise((resolve , rejects)=>{
                const refreshToken = getLocalStore("refresh_token") || getSessionStore("refresh_token");
                
                axios.post(`/api/auth/refreshToken` , { refreshToken : refreshToken} ,
                {
                    headers : {
                        Authorization :'Bearer ' + refreshToken
                    }
                }).then( response => {

                    setSessionStore("account_token" , response.data.token);
                    config.headers!!['Authorization'] = 'Bearer ' + response.data.token;

                    resolve(axios(config));
                }).catch( error => {
                    rejects(error);
                });
            });
            
        } else {
            
            if(error.message.includes('timeout')){
                return Promise.reject(msg(ResponseStatus.TIMEOUT));
            } else {
                return Promise.reject(msg(ResponseStatus.UNKNOW));
            }
        } 
    }
);

export const requestAllFn = (dispatch : Dispatch<FetchActions> ,params:Array<Params>): {[key:string]:any} =>{
    return new Promise((reslove, reject)=>{
        dispatch({
            type:'fetch_begin' ,
            payload : {
                pageLoading : true
            }
        });

        const account_token = getSessionStore('account_token');
        const axiosArray = new Array();
        params.forEach(param => {
            axiosArray.push(axios.request({
                url:param.url,
                method:param.method || 'get',
                headers:{
                    ...(account_token ? { Authorization: `Bearer ${account_token}` } : {}),
                    ...(param.data && typeof param.data === 'string' ? { 'Content-Type': 'text/uri-list' } : {})
                },
                data : param.data ,
                timeout : REQUEST_TIME_OUT , 
                validateStatus:status => {
                    return status !== 401
                },
                responseType : param.responseType || 'json',
                withCredentials:true
            }));

        });
        axios.all(axiosArray).then(axios.spread((...responses:Array<AxiosResponse>)=>{
            let responseData:{[key:string]:any} = {};
            for(let i = 0 ; i < responses.length ; i++ ){
                if(responses[i].status == 200){
                    if(responses[i].data["code"] == 200 ){
                        delete responses[i].data["code"]
                        responseData[i] = responses[i].data;
                    } else {
                        responseData[i] = {}
                    }
                    
                }

            }
            reslove(responseData);
        })).catch(error => {
            reject(error);
        });
        
    });
}

export const requestFn = (dispatch : Dispatch<FetchActions> ,params:Params): AxiosPromise =>{
    return new Promise((reslove, reject)=>{
        dispatch({
            type:'fetch_begin' ,
            payload : {
                pageLoading : true
            }
        });

        const account_token = getSessionStore('account_token');
        axios.request({
            url : params.url ,
            method:params.method || 'get',
            headers : {
                ...(account_token ? { Authorization: `Bearer ${account_token}` } : {}),
                ...(params.data && typeof params.data === 'string' ? { 'Content-Type': 'text/uri-list' } : {})
            },
            data : params.data ,
            timeout : REQUEST_TIME_OUT , 
            validateStatus:status => {
                return status !== 401
            },
            responseType : params.responseType || 'json',
            withCredentials:true
        }).then(response =>{
            dispatch({
                type: 'fetch_success',
                payload: {
                    pageLoading: false
                }
            });
            reslove(response);
        }).catch(error =>{
            dispatch({
                type: 'fetch_failed',
                payload: {
                    pageLoading: false
                }
            });
            reject(error);
        });
    });
}

const msg = (status:ResponseStatus):IErrorResponse =>{
    let info:IErrorResponse = {
        message: '',
        code: ResponseStatus.UNKNOW
    };
    switch(status){
        case ResponseStatus.TIMEOUT :
            info.code = ResponseStatus.TIMEOUT;
            info.message = "伺服器連線逾時";
            break;
        case ResponseStatus.UNAUTHORIZED :
            info.code = ResponseStatus.UNAUTHORIZED;
            info.message = "Token已失效，請重新登入";
            break;
        case ResponseStatus.UNKNOW_CAR :
            info.code = ResponseStatus.UNKNOW_CAR;
            info.message = "無入場資訊";
            break;
        case ResponseStatus.ERROR_CARSPACE_DATABASE :
            info.code = ResponseStatus.ERROR_CARSPACE_DATABASE;
            info.message = `DataBase Error : ${ResponseStatus.ERROR_CARSPACE_DATABASE}`;
            break;
        case ResponseStatus.ERROR_CARSPACE_FORMAT :
            info.code = ResponseStatus.ERROR_CARSPACE_FORMAT;
            info.message = "資訊格式錯誤";
            break;
        case ResponseStatus.ERROR_CARSPACE_NO_DATA :
            info.code = ResponseStatus.ERROR_CARSPACE_NO_DATA;
            info.message = "查無資料";
            break;
        case ResponseStatus.ERROR_CARSPACE_TARGET :
            info.code = ResponseStatus.ERROR_CARSPACE_TARGET;
            info.message = "場地編碼錯誤";
            break;
        case ResponseStatus.ERROR_CARSPACE_ENC_DEC :
            info.code = ResponseStatus.ERROR_CARSPACE_ENC_DEC;
            info.message = "資訊錯誤";
            break;
        case ResponseStatus.ERROR_CARSPACE_SYSTEM :
            info.code = ResponseStatus.ERROR_CARSPACE_SYSTEM;
            info.message = "系統辨識錯誤";
            break;
        case ResponseStatus.ERROR_CARSPACE_TICKET :
            info.code = ResponseStatus.ERROR_CARSPACE_TICKET;
            info.message = "發票號碼異常";
            break;
        case ResponseStatus.ERROR_CARSPACE_CAR_END_DATE :
            info.code = ResponseStatus.ERROR_CARSPACE_CAR_END_DATE;
            info.message = "車格使用結束期限不符合";
            break;
        case ResponseStatus.ERROR_CARSPACE_CAR_PAY_DATE :
            info.code = ResponseStatus.ERROR_CARSPACE_CAR_PAY_DATE;
            info.message = "車格繳費月份不符合";
            break;
        case ResponseStatus.ERROR_CARSPACE_SYSTEM_MAINTENANCE :
            info.code = ResponseStatus.ERROR_CARSPACE_SYSTEM_MAINTENANCE;
            info.message = "系統異常";
            break;
        case ResponseStatus.ERROR_CARSPACE_SYSTEM_EXCEPTION :
            info.code = ResponseStatus.ERROR_CARSPACE_SYSTEM_EXCEPTION;
            info.message = "系統維護中";
            break;
        default:
            info.message = "未知錯誤...";
            break;
    }
    return info;
}
const fn = { 
    requestFn , 
    requestAllFn
}