
import loadable from '@loadable/component';
import Loading from '@components/Loading';
import { IPageProps } from '@components/Page';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '@store/ContextStore';
import useLocationRange from '@use/useLocationRange';

const MainLayout = loadable( () => import('@layouts/Main') , {
    fallback:<Loading />,
} );
const Menu = loadable( () => import('@views/Menu') , {
    fallback:<Loading />,
});

  
const LandMark = loadable( () => import('@views/LandMark') , {
    fallback:<Loading />,
} );
  
const ParkingSpace = loadable( () => import('@views/Parking') , {
    fallback:<Loading />,
} );
  
const GuideImageMap = loadable( () => import('@views/GuideMap/GuideImageMap') , {
    fallback:<Loading />,
} );
  
const GuideCarMap = loadable( () => import('@views/GuideMap/GuideCarMap') , {
    fallback:<Loading />,
} );
const CarSpace = loadable( () => import('@views/CarSpace') , {
    fallback:<Loading />,
} );

const OutOfRange = loadable( () => import('@views/Hint/OutOfRange') , {
    fallback:<Loading />,
} );

const GPS = loadable( () => import('@views/Hint/GPS') , {
    fallback:<Loading />,
} );
const NotSupport = loadable( () => import('@views/Hint/NotSupport') , {
    fallback:<Loading />,
} );

const RequireLocation = () => {
    const location = useLocation();
    const { state  } = useContext(AppContext);
    const auth = useLocationRange();
    
    if(auth.scope == 0){
        return <Navigate to="/404" state={{ from: location }} />;
    } else if(auth.scope == 1 && auth.distance >= 500 || auth.scope == 3 && auth.distance >= 500){
        return <Navigate to="/hint/range" state={{ from: location }} />;
    } else if(auth.scope == 5){
        return <Navigate to="/hint/gps" state={{ from: location }} />;
    } else if(auth.scope == 3){
        return <Navigate to="/car/" state={{ from: location }} />;
    } else if(auth.scope == 4){
        return <Navigate to="/car/space" state={{ from: location }} />;
    }
    return <Outlet />;
}

const RequireSearchCar = () => {
    const location = useLocation();
    const { state  } = useContext(AppContext);
    const auth = useLocationRange();
    if(auth.scope == 0){
        return <Navigate to="/404" state={{ from: location }} />;
    } else if(auth.scope == 1 && auth.distance >= 500 || auth.scope == 3 && auth.distance >= 500){
        return <Navigate to="/hint/range" state={{ from: location }} />;
    } else if(auth.scope == 5){
        return <Navigate to="/hint/gps" state={{ from: location }} />;
    } else if(auth.scope == 4){
      return <Navigate to="/car/space" state={{ from: location }} />;
    }
    return <Outlet />;
}

const routes :Array<IPageProps> = [
    {
        path:"/car/space",
        element:<CarSpace />
    } ,
    {
        path:"/hint",
        element:<MainLayout><Outlet /></MainLayout>,
        children:[
            {
                path:"gps",
                element:<GPS />
            } ,
            {
                path:"range",
                element:<OutOfRange />
            } ,
        ]
    } ,
    {
        path:"/404",
        element:<MainLayout><NotSupport /></MainLayout>
    } ,
    {
        path:"/car",
        element:<MainLayout><RequireSearchCar /></MainLayout>,
        children:[
            {
                path:"",
                element:<ParkingSpace />
            } ,
        ]
    } ,
    {
        path:"/guidemap/car",
        element:<MainLayout><RequireSearchCar /></MainLayout>,
        children:[
            {
                path:"",
                element:<GuideCarMap />
            } ,
        ]
    } ,
    {
        path : "/",
        element:<MainLayout><RequireLocation /></MainLayout> ,
        children:[
            {
                path:"/",
                element:<Menu />
            } ,
            {
                path:"/landmark",
                element:<LandMark />
            },
            {
                path:"/guidemap/:type",
                element:<GuideImageMap />
            }
        ]
    }
    
]

export default routes;