import { createTheme } from "@mui/material";


const theme = createTheme({
  palette: {
    primary: {
      light: '#eba534',
      main: '#E68F02',
      dark: '#a16401',
      contrastText: '#000'
    } ,
    menu : {
      main: '#F0D17F',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  } 
});

theme.components = {
  MuiContainer:{
    styleOverrides:{
      root:{
        height:'calc(100vh - 56px)' ,
        [`${theme.breakpoints.up('xs')} and (orientation: Landscape)`]: {
          height:'calc(100vh - 48px)'
        },
        [theme.breakpoints.up('sm')]: {
          height:'calc(100vh - 64px)'
        },
      }
    }
  }
}

export default theme;