
// baseUrl
export const baseUrl = '/parking';

export const GET_PLACE_QR_INFO_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/qr/%s/`;
export const GET_PLACE_MARK_INFO_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/mark/%s/`;
export const GET_PLACE_LANDMARK_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/landmark/`;
export const GET_CAR_TRICKET_INFO_API = `${baseUrl}/tricket/place/%s/space/%s/floor/%s/qr/%s/car/%s`;
export const POST_GUILD_ROUTES_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/qr/%s/guidemap/car`;

export const GET_POINT_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/qr/%s/guidemap/points`;
export const GET_LOCATION_API = `${baseUrl}/info/location/%s`;
export const GET_CARSPACE_API = `${baseUrl}/info/carspace/%s`;

export const GET_MAP_IMG_API = `${baseUrl}/map/place/%s/space/%s/floor/%s/qr/%s/guidemap/map`;



const APIs = {
    GET_PLACE_QR_INFO_API : GET_PLACE_QR_INFO_API ,
    GET_PLACE_MARK_INFO_API : GET_PLACE_MARK_INFO_API ,
    GET_PLACE_LANDMARK_API : GET_PLACE_LANDMARK_API,
    GET_CAR_TRICKET_INFO_API : GET_CAR_TRICKET_INFO_API,
    POST_GUILD_ROUTES_API : POST_GUILD_ROUTES_API,
    GET_POINT_API:GET_POINT_API,
    GET_LOCATION_API:GET_LOCATION_API,
    GET_CARSPACE_API:GET_CARSPACE_API,
    GET_MAP_IMG_API:GET_MAP_IMG_API
}
export default APIs;