import { useContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { AppContext } from '@store/ContextStore';
import { LocationProvider } from '@provider/LocationProvider';
import routes from '@routes/routes';

const App = () => {
  
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const routing = useRoutes(routes);

  useEffect(()=>{
    if(state.place.isConnectServer){
      setLoading(false);
    }
  } , [state.place.isConnectServer]);
  return (
    <LocationProvider>
    {
      loading ?　<Box sx={{ display: 'flex' , flexDirection: 'column', justifyContent:"center",
      alignItems:"center" , height:"100vh" }}> 
        
      <CircularProgress size ={50} /> </Box> : 
        <>
        {
          routing
        }
        
        </>
    }
    </LocationProvider>
    
  )
}

export default App;
