enum ResponseStatus {
    SUCCESS = 200,
    UNAUTHORIZED = 401 ,
    TIMEOUT = 408,
    UNKNOW = 999,
    UNKNOW_CAR = 1001,
    ERROR_CARSPACE_DATABASE = 1002,
    ERROR_CARSPACE_FORMAT = 1003,
    ERROR_CARSPACE_NO_DATA = 1004,
    ERROR_CARSPACE_TARGET = 1005,
    ERROR_CARSPACE_ENC_DEC = 1006,
    ERROR_CARSPACE_SYSTEM = 1007,
    ERROR_CARSPACE_TICKET = 1008,
    ERROR_CARSPACE_CAR_END_DATE = 1009,
    ERROR_CARSPACE_CAR_PAY_DATE = 1010,
    ERROR_CARSPACE_SYSTEM_MAINTENANCE = 1011,
    ERROR_CARSPACE_SYSTEM_EXCEPTION = '9999'
}

export default ResponseStatus;