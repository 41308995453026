import { IPlaceQrPoint } from '@interface/IPlaceQrPoint'
import FetchPlaceActions from '@store/actions/fetchPlaceActions'

/** State 接口 */
export interface FetchPlaceState {
  payload: IPlaceQrPoint
  isConnectServer :boolean
}

/** 初始狀態 */
export const INITIAL_PLACE_STATE: FetchPlaceState = {
  payload:{
    car_space_code:"",
    car_space_pgs:"",
    car_space_name:"",
    pl_code:"",
    qr_floor:"",
    qr_id:0,
    car_space_guild:true
  },
  isConnectServer:false,
}


/** 默認 reducer */
export default function fetchReducer(state: FetchPlaceState = INITIAL_PLACE_STATE, action: FetchPlaceActions ) {
  switch (action.type) {
    case 'SET_PLACE': {
      return {
        ...state,
        payload: action.payload 
      }
    }
    case 'SET_CONNECTSERVER': {
      return {
        ...state,
        isConnectServer: action.isConnectServer
      }
    }
    default:
      return state
  }
}
